<template>
  <div class="res">
    <div class="res-box">
      <div class="res-unit">{{ bookModel.bookName }}</div>
      <div class="res-cover">
        <!-- :src="bookModel.coverPicUrl" -->
        <van-image
          fit="contain"
          :src="
            bookModel.coverPicUrl
              ? bookModel.coverPicUrl
              : 'https://53english.cdn.53online.cn/cover.png'
          "
        />
      </div>
      <div class="res-title">
        <p class="res-title-left">{{ resModel.catalogTitle }}</p>
        <p class="res-title-center">
          {{
            resModel.name == "听力" || resModel.name == "跟读"
              ? ""
              : resModel.name
          }}
        </p>
      </div>
      <div class="res-progress">
        <div class="res-progress-timeText">
          <span>{{ progressText }}</span>
          <span>{{ totalTime }}</span>
        </div>
        <van-slider
          v-model="progress"
          @change="progressChange"
          bar-height="2px"
          inactive-color="#FFD37D"
          active-color="#FF8002"
          ><template #button>
            <div class="res-progress-button"></div>
          </template>
        </van-slider>
      </div>
      <div class="res-fun">
        <div class="loop" @click="loopClick">
          <van-image
            fit="contain"
            :src="
              loop
                ? 'https://i.cdn.quyixian.com/53english/loop.png'
                : 'https://i.cdn.quyixian.com/53english/loop1.png'
            "
          />
        </div>
        <div class="upper">
          <van-image
            v-if="upperShow"
            @click="upperClick"
            fit="contain"
            src="https://i.cdn.quyixian.com/53english/upper.png"
          />
          <van-image
            v-else
            fit="contain"
            src="https://i.cdn.quyixian.com/53english/upper-grey.png"
          />
        </div>
        <div class="play" @click="togglePlaying">
          <van-image
            fit="contain"
            :src="
              audioState
                ? 'https://i.cdn.quyixian.com/53english/pause.png'
                : 'https://i.cdn.quyixian.com/53english/play.png'
            "
          />
        </div>
        <div class="next">
          <van-image
            v-if="nextShow"
            @click="nextClick"
            fit="contain"
            src="https://i.cdn.quyixian.com/53english/next.png"
          />
          <van-image
            v-else
            fit="contain"
            src="https://i.cdn.quyixian.com/53english/next-grey.png"
          />
        </div>
        <div class="speed" @click="speedShow = true">{{ speedText }}</div>
      </div>
      <div class="res-allDown" @click="tapPopup">
        <van-image
          class="res-allDown-img"
          fit="contain"
          src="https://53english.cdn.53online.cn/a-down.png"
        />
      </div>
    </div>
    <div class="res-footer">
      <div v-if="resModel.hasNodes == 1" class="res-footer-box res-footer-box1">
        <div class="res-footer-btn res-footer-btn2" @click="listShow = true">
          分题选听
        </div>
      </div>
      <div
        v-else-if="resModel.hasFollowRead == 1"
        class="res-footer-box res-footer-box2"
      >
        <template v-if="resModel.hasSectionLRC == 1">
          <router-link
            class="res-footer-btn res-footer-btn3"
            :to="{
              name: 'fullread',
              query: {
                rid: resModel.id,
                rname: resModel.name,
                bid: bookModel.bookId,
                bname: bookModel.bookName,
                type: 4,
                title: '全文跟读',
              },
            }"
            >全文跟读</router-link
          >
          <router-link
            v-if="resModel.hasSentenceLRC == 1"
            class="res-footer-btn res-footer-btn3"
            :to="{
              name: 'read',
              query: {
                rid: resModel.id,
                rname: resModel.name,
                bid: bookModel.bookId,
                bname: bookModel.bookName,
                type: 3,
                title: '逐句跟读',
              },
            }"
            >逐句跟读</router-link
          >
        </template>
        <template v-else>
          <router-link
            v-if="resModel.hasWordLRC == 1"
            class="res-footer-btn res-footer-btn3"
            :to="{
              name: 'read',
              query: {
                rid: resModel.id,
                rname: resModel.name,
                bid: bookModel.bookId,
                bname: bookModel.bookName,
                type: 1,
                title: '词汇跟读',
              },
            }"
            >词汇跟读</router-link
          >
          <router-link
            v-if="resModel.hasPhraseLRC == 1"
            class="res-footer-btn res-footer-btn3"
            :to="{
              name: 'read',
              query: {
                rid: resModel.id,
                rname: resModel.name,
                bid: bookModel.bookId,
                bname: bookModel.bookName,
                type: 2,
                title: '短语跟读',
              },
            }"
            >短语跟读</router-link
          >
          <router-link
            v-if="resModel.hasSentenceLRC == 1"
            class="res-footer-btn res-footer-btn3"
            :to="{
              name: 'read',
              query: {
                rid: resModel.id,
                rname: resModel.name,
                bid: bookModel.bookId,
                bname: bookModel.bookName,
                type: 3,
                title: '句型跟读',
              },
            }"
            >句型跟读</router-link
          >
        </template>
      </div>
    </div>
    <audio
      id="inaudio"
      ref="inaudio"
      @play="onPlay"
      @pause="onPause"
      @ended="overAudio"
      style="height: 0"
      :src="resModel.resFileUrl"
    ></audio>
    <van-popup v-model="speedShow" round position="bottom">
      <div class="speed-popup">
        <div class="speed-popup-title">请选择倍速</div>
        <ul class="speed-popup-list">
          <li
            :class="item.key == speed ? 'cur' : ''"
            v-for="item in speedList"
            @click="speedClick(item.key, item.value)"
            :key="item.key"
          >
            {{ item.value }}
          </li>
        </ul>
        <div class="speed-popup-close" @click="speedShow = false">关闭</div>
      </div>
    </van-popup>
    <van-popup v-model="listShow" round closeable position="bottom">
      <div class="block-popup">
        <template v-if="list.length > 0">
          <div class="block-list" v-for="(item, idx) in list" :key="idx">
            <div class="block-list-title">{{ item.section }}</div>
            <div class="block-list-content">
              <p
                v-for="(j, jdx) in item.values"
                :key="jdx"
                @click="clickLevels(idx, jdx, j.start)"
              >
                {{ j.title }}
              </p>
            </div>
          </div>
        </template>
        <div v-else class="block-popup-nolist">无小节数据</div>
      </div>
    </van-popup>
    <van-popup v-model="downShow" style="width: 90%" closeable>
      <div class="downpopup" v-if="bookModel">
        <div class="downpopup-title">
          {{ bookModel.bookName }}
        </div>
        <div class="downpopup-bottom">
          <div class="downpopup-btn">
            <span
              v-if="isIos"
              @click="downFile(bookModel.allResUrl)"
              class="down"
              >下载</span
            >
            <span class="down down2" v-if="isWxBrowser && !isIos"
              >点击右上角···选择默认浏览器打开</span
            >
            <template v-else>
              <span
                v-if="isAnd"
                @click="downFile(bookModel.allResUrl)"
                class="down"
                >下载</span
              >
            </template>
          </div>
          <div class="downpopup-demo">
            <span v-if="isIos" class="down1" @click="isIosShow = true"
              >苹果手机下载后如何查看资源</span
            >
            <!-- <span v-if="isAnd" class="down1" @click="isAndShow = false"
              >安卓手机下载后如何查看资源</span
            > -->
          </div>
        </div>
        <div class="downpopup-mail">
          <van-field v-model="mail" placeholder="输入邮箱" />
          <van-button class="down" @click="tapMail">下载至邮箱</van-button>
        </div>
      </div>
    </van-popup>
    <van-image-preview v-model="isIosShow" :images="iosList" />
    <van-image-preview v-model="isAndShow" :images="andList" />
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
export default {
  name: "audiodetail",
  data() {
    return {
      audioState: false, //播放状态
      currentTime: "00:00", //当前时长
      totalTime: "00:00", //总时长
      progress: 0, //进度
      progressText: "00:00", //=currentTime
      loop: false, //循环方式 true列表循环 false单循环
      speed: 1.0, //播放速度
      speedText: "倍速",
      speedShow: false,
      speedList: [
        {
          key: 0.5,
          value: "0.5X",
        },
        {
          key: 0.75,
          value: "0.75X",
        },
        {
          key: 1,
          value: "1.0X",
        },
        {
          key: 1.25,
          value: "1.25X",
        },
        {
          key: 1.5,
          value: "1.5X",
        },
        {
          key: 2.0,
          value: "2.0X",
        },
      ],
      upperShow: true, //是否能上一首
      nextShow: true, //是否能下一首
      list: [],
      listShow: false,
      //
      id: this.$route.query.id == undefined ? "" : this.$route.query.id, //参数ID  589783029321306112
      bookModel: {},
      resModel: {},
      downShow: false,
      mail: "",
      isIos: false,
      isIosShow: false,
      iosList: [
        "https://53english.cdn.53online.cn/ios/1.png",
        "https://53english.cdn.53online.cn/ios/2.png",
        "https://53english.cdn.53online.cn/ios/3.png",
        "https://53english.cdn.53online.cn/ios/4.png",
        "https://53english.cdn.53online.cn/ios/5.png",
        "https://53english.cdn.53online.cn/ios/6.png",
        "https://53english.cdn.53online.cn/ios/7.png",
      ],
      isAnd: false,
      isAndShow: false,
      andList: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg",
      ],
      isWxBrowser: false,
    };
  },
  mounted() {
    let that = this;
    this.getModel();
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.isIos = /iphone|ipad|ipod/.test(userAgent);
    this.isAnd = /android/.test(userAgent);
    this.isWxBrowser = /micromessenger/.test(userAgent);

    //监听播放
    document
      .getElementById("inaudio")
      .addEventListener("timeupdate", function () {
        let current = this.currentTime;
        let duration = this.duration;
        //设置进度
        that.progress = (current / duration) * 100;
        //设置播放时长
        that.progressText = that.stringifySecond(current);
        that.currentTime = that.stringifySecond(current);
        that.totalTime = that.stringifySecond(duration);
        console.log(
          "当前毫秒：" +
            current +
            ",总毫秒：" +
            duration +
            ",进度：" +
            that.progress
        );
      });
  },
  methods: {
    tapMail() {
      if (this.isValidEmail(this.mail)) {
        let params = {
          BookName: this.book.bookName,
          AllResUrl: this.book.allResUrl,
          Email: this.mail,
        };
        this.$api.res.sendEmail(params).then(({ tag }) => {
          if (tag == 1) {
            Toast("邮件发送成功");
            this.downShow = false;
          } else {
            Toast("邮件发送失败");
          }
        });
      } else {
        Toast("请输入正确的邮箱");
      }
    },
    isValidEmail(email) {
      // 使用正则表达式判断邮箱格式
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailRegex.test(email);
    },
    tapPopup() {
      this.downShow = !this.downShow;
      if (this.downShow == true && this.isWxBrowser) {
        let params = new URLSearchParams(window.location.search);
        let url = window.location.href;
        let down = params.get("down");
        if (down != "true") {
          url = window.location.href + "&down=true";
        }
        window.history.replaceState(
          {
            path: url,
          },
          "",
          url
        );
      }
    },
    downFile(url) {
      window.location.href = url;
    },
    //切换小节
    clickLevels(it, jt, start) {
      //设置播放时间
      let audio = this.$refs.inaudio;
      audio.currentTime = start;
      setTimeout(() => {
        audio.play();
      }, 100);
      this.listShow = false;
    },

    //播放，暂停
    togglePlaying() {
      let audio = this.$refs.inaudio;
      if (audio.paused) {
        setTimeout(() => {
          audio.play();
        }, 100);
      } else {
        audio.pause();
      }
    },
    //重播
    currentPlay() {
      let audio = this.$refs.inaudio;
      audio.currentTime = 0;
      setTimeout(() => {
        audio.play();
      }, 100);
    },

    //监听播放
    onPlay() {
      this.audioState = true;
    },
    //监听暂停
    onPause() {
      this.audioState = false;
    },
    //播放完毕
    overAudio() {
      if (this.loop) {
        this.nextClick();
      } else {
        //播放完毕且默认单曲循环下回滚暂停
        this.currentPlay();
      }
    },
    //列表上一曲
    upperClick() {
      if (this.upperShow) {
        let params = {
          catalogId: this.resModel.catalogId,
          sort: this.resModel.sort,
        };
        this.$api.res.getPre(params).then(({ data, tag }) => {
          if (tag == 1) {
            this.upperShow = true;
            this.nextShow = true;
            this.update(data, "upper");
            Toast("上一个");
          } else {
            Toast("没有上一个啦");
            this.upperShow = false;
          }
        });
      } else {
        Toast("没有啦");
      }
    },
    //列表下一曲
    nextClick() {
      if (this.nextShow) {
        let params = {
          catalogId: this.resModel.catalogId,
          sort: this.resModel.sort,
        };
        this.$api.res.getNext(params).then(({ data, tag }) => {
          if (tag == 1) {
            this.upperShow = true;
            this.nextShow = true;
            this.update(data, "next");
            Toast("下一个");
          } else {
            Toast("没有下一个啦");
            this.nextShow = false;
          }
        });
      } else {
        Toast("没有啦");
      }
    },
    //进度条
    progressChange(event) {
      let audio = this.$refs.inaudio;
      this.progress = event;
      audio.currentTime = audio.duration * (event / 100);
      //拖动播放
      if (!this.audioState) {
        this.togglePlaying();
      }
      // let duration = audio.duration;
      // let currentP = event;
      // let currentT = duration * (currentP / 100);
      // this.currentTime = this.stringifySecond(currentT); //监听会自动赋值
    },
    //循环模式
    loopClick() {
      //true 列表循环  false 单个循环
      if (this.loop) {
        this.loop = false;
        Toast("单曲循环");
      } else {
        this.loop = true;
        Toast("列表循环");
      }
    },
    //倍速设置
    speedClick(k, v) {
      this.speed = k;
      this.speedText = v;
      let audio = this.$refs.inaudio;
      audio.playbackRate = k;
    },

    //分秒转换秒
    // stringify1(v) {
    //   let min = v.split(':')[0];
    //   let sec = v.split(':')[1];
    //   return Number(min * 60) + Number(sec);
    // },
    //秒转换分秒
    //秒转分秒
    stringifySecond(v) {
      let m = parseInt(v / 60);
      let s = parseInt(v % 60);
      return (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;
    },
    //下载
    down() {
      //父级事件
      this.$emit("down");
    },
    //获取数据列表
    async getList() {
      const folders = sessionStorage.getItem(this.res.bid);
      if (folders == null) {
        sessionStorage.clear();
        let params = {
          bid: this.res.bid,
          fid: this.res.fid,
        };
        let info = await this.$api.mary.GetFolderInfo(params);
        sessionStorage.setItem(info.data.bid, qs.stringify(info));
        this.box = info;
      } else {
        this.box = qs.parse(sessionStorage.getItem(this.res.bid));
      }
    },
    //获取资源
    async getModel() {
      const { data, tag, message } = await this.$api.res.getModel({
        id: this.id,
      });
      if (tag == 1) {
        this.update(data);
      } else {
        Dialog.alert({ title: "获取数据失败", message: message }).then(() => {
          //点击事件
        });
      }
    },
    //更新数据
    update(data, state) {
      this.$nextTick(() => {
        this.bookModel = {
          bookId: data.bookId,
          bookName: data.bookName,
          bookcode: data.bookcode,
          coverPicUrl: data.coverPicUrl,
          publisher: data.publisher,
          allResUrl: data.allResUrl,
        };
        this.resModel = data.resModel;
        if (data.resModel.hasNodes == 1 && data.resModel.nodesList != null) {
          this.list = data.resModel.nodesList;
        }
        if (state) {
          this.id = data.resModel.id;
          //更新数据
          let audio = this.$refs.inaudio;
          audio.currentTime = 0;
          this.currentTime = "00";
          this.totalTime = "00";
          this.progress = 0;
          this.progressText = "00";
          this.audioState = false;
        }
      });
      let that = this;
      setTimeout(() => {
        that.togglePlaying();
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.res {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 40px;
    width: 90%;
    position: relative;
  }

  &-unit {
    font-size: 42px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-cover {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .van-image {
      display: flex;
    }
    img {
      max-height: 600px;
    }
  }

  &-progress {
    margin: 30px 0;
    width: 90%;
    position: relative;

    .van-slider__button-wrapper {
      z-index: 10;
    }

    &-button {
      color: #fff;
      font-size: 28px;
      text-align: center;
      background-color: #ff8002;
      // padding: 6px 12px;
      padding: 12px;
      border-radius: 50px;
    }

    // &::before,
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 50%;
    //   background-color: #ff8002;
    //   width: 12px;
    //   height: 12px;
    //   border-radius: 100%;
    //   transform: translateY(-50%);
    //   z-index: 1;
    // }
    // &::before {
    //   left: 0;
    // }
    // &::after {
    //   right: 0;
    // }
    &-timeText {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      color: #999;
    }
  }

  &-title {
    margin: 30px 0;
    width: 100%;
    font-size: 32px;
    font-weight: bold;

    &-left {
      text-align: left;
      margin-bottom: 30px;
    }
    &-center {
      text-align: left;
    }
  }

  &-fun {
    width: 100%;
    margin: 30px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .van-image {
      display: flex;
    }

    .loop {
      width: 80px;
      height: 40px;
      .van-image {
        height: 40px;
      }
    }

    .play {
      width: 110px;
      height: 110px;
      // border-radius: 100px;
      // font-size: 92px;
      // background: linear-gradient(128deg, #f9b228 0%, #ff7d00 100%);
      // box-shadow: 0 0 10px #ccc;
    }
    .upper,
    .next {
      width: 55px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .speed {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.5px solid #999;
      color: #999;
      border-radius: 20px;
      height: 40px;
      width: 80px;
      font-size: 24px;
    }
  }

  &-allDown {
    position: absolute;
    right: 0;
    bottom: -80px;

    &-img {
      width: 209px;
      height: auto;
    }
  }

  &-footer {
    width: 100%;
    border-top: 10px solid #ececec;

    &-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 80px 40px;
    }
    &-box2 {
      justify-content: space-evenly;
    }
    &-box1 {
      justify-content: center;

      .res-footer-btn {
        width: 100%;
      }
    }

    &-btn {
      background: #ede8ff;
      color: #6e49fc;
      padding: 30px 40px;
      border-radius: 10px;
      font-size: 28px;
      text-align: center;
    }

    &-cur {
      background: #6e49fc;
      color: #fff;
    }
  }
}
.speed-popup {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  &-title {
    font-size: 32px;
    margin: 30px 0;
  }

  &-list {
    width: 80%;

    li {
      width: 100%;
      text-align: center;
      font-size: 28px;
      line-height: 3;

      &:not(&:last-child) {
        border-bottom: 1px solid #eee;
      }
    }

    .cur {
      color: #00b3ed;
    }
  }

  &-close {
    width: 80%;
    text-align: center;
    font-size: 32px;
    border-top: 1px solid #ccc;
    padding: 30px 0;
  }
}
.block {
  &-popup {
    padding: 60px 40px;

    &-nolist {
      text-align: center;
      font-size: 28px;
      color: #999;
      margin: 20px 0;
    }
  }
  &-list {
    margin-top: 60px;
    &-title {
      color: #6e49fc;
      font-weight: bold;
      font-size: 32px;
    }
    &-content {
      font-size: 28px;
      margin: 30px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      p {
        margin-right: 30px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
